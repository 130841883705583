import React, { useState, useMemo, useEffect, useCallback } from "react";
import ActionBar from "./ActionBar";
import styled from "styled-components";
import { useLocation, navigate } from "@reach/router";
import _ from "lodash";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
// import { makeStyles, Select } from "@material-ui/core";
// import { TextType } from "../../../helpers/constants";
import { Slate, Editable, withReact } from "slate-react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
// import { PauseOutlined, PlayArrow } from "@material-ui/icons";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import "./Audio.css";

const CrossExaminationTranscript = (id) => {
  const history = useLocation();
  const resolutionKind = history.search.replace("?caseType=", "");
  const breadcrumbs = [
    "Cases",
    _.startCase(resolutionKind),
    "Cross Examination",
  ];
  const editor = useMemo(() => withReact(withHistory(createEditor())), []);
  const [value, setValue] = useState([]);
  //   const videoRef = useRef(null);
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [referesh, setReferesh] = useState(true);
  const [render, setRender] = useState(false);
  const [data, setData] = useState([]);
  const transcriptId = history?.state?.id;
  const status = true;

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case resolutionKind:
        navigate(`/mediator/cases/${id?.id}?caseType=${resolutionKind}`);
        break;
      case "cases":
        navigate(`/mediator/cases?caseType=${resolutionKind}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const getTranscriptionData = async (transcriptId) => {
      try {
        setLoader({ state: true, message: "Fetching transcript..." });
        const response = await CaseService.getLiveTranscription(transcriptId);
        if (response) {
          setData(
            response?.transcriptData?.map((el) => {
              return {
                speaker: el.speaker ? el?.speaker : "test",
                text: el.text,
                textType: el.textType ? el.textType : "Question",
                mediaId: el?.mediaId,
                questionNo: el?.questionNo,
                audioUrl: el?.audioUrl,
              };
            })
          );
          setRender(true);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    };
    if (referesh) {
      getTranscriptionData(transcriptId);
      setReferesh(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referesh]);

  useEffect(() => {
    if (data) {
      const response = splitContent(data);
      setValue(response);
      setRender(false);
    }
    if (value) {
      editor.history.undos = [];
      editor.children = value;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render]);

  // const usePlaceholderStyles = makeStyles((theme) => ({
  //   placeholder: {
  //     color: COLORS.INPUT_GRAY,
  //   },
  //   selectInput: {
  //     color: "#293461", // Change the font color here
  //     fontFamily: theme.fonts.primaryFontSemiBold, // Change the font family here
  //   },
  // }));

  const generateText = (words) => {
    return words.text;
  };

  // const Placeholder = ({ children }) => {
  //   const classes = usePlaceholderStyles();
  //   return <div className={classes.placeholder}>{children}</div>;
  // };

  // const handleChange = (e, element) => {
  //   const pathToCurrentNode = ReactEditor.findPath(editor, element);
  //   const value = e.target.value;
  //   if (value) {
  //     Transforms.setNodes(
  //       editor,
  //       { type: "timedText", textType: value },
  //       { at: pathToCurrentNode }
  //     );
  //   }
  // };

  const splitContent = (paragraphs) => {
    return paragraphs.map((paragraph, index) => ({
      speaker: paragraph.speaker,
      id: paragraph.id,
      audioUrl: paragraph.audioUrl,
      textType: paragraph.textType,
      type: "timedText",
      currentTime: paragraph.currentTime,
      duration: paragraph.duration,
      mediaId: paragraph.mediaId,
      questionNo: paragraph.questionNo,
      children: [
        {
          text: generateText(paragraph),
        },
      ],
    }));
  };

  const renderLeaf = useCallback(({ attributes, children, leaf }) => {
    return (
      <span className={"timecode text"} {...attributes}>
        {children}
      </span>
    );
  }, []);

  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      case "timedText":
        return <TimedTextElement {...props} />;
      default:
        return <DefaultElement {...{ props }} />;
    }
  }, []);

  const DefaultElement = (props) => {
    return <p {...props.attributes}>{props.children}</p>;
  };

  const TimedTextElement = (props) => {
    return (
      <React.Fragment>
        <DrawerContainer contentEditable={false}>
          {/* <Select
            style={{
              height: "32px",
              width: "11%",
              borderRadius: "5px",
              color: "#293461",
              fontFamily: theme.fonts.primaryFontSemiBold,
            }}
            disableUnderline={true}
            defaultValue={props.element.textType}
            renderValue={
              props.element.textType !== ""
                ? undefined
                : () => <Placeholder>{"Select Type"}</Placeholder>
            }
          >
            {TextType.map((element, index) => (
              <StyledMenuItem
                key={index}
                value={element.value}
                disabled={element.disabled}
              >
                {element.label}
              </StyledMenuItem>
            ))}
          </Select> */}
          <ValueSpeaker style={{ color: "#293461" }}>
            {props.element.textType}
          </ValueSpeaker>
          <ValueSpeaker>{props.element.speaker}</ValueSpeaker>
          <ValueConfidence>{props.element.questionNo}</ValueConfidence>
          <AudioBar>
            {props.element.audioUrl ? (
              <div className="audio-player-container">
                <audio className="audio-player" controls>
                  <source src={props.element.audioUrl} />
                </audio>
              </div>
            ) : null}
            {/* <audio
                id="audioUrlId"
                ref={videoRef}
                src={props.element.audioUrl}
                onLoadedMetadata={(event) =>
                  handleMetaDataTimeUpdate(event, props.element)
                }
                // controls
                // playsInline
                // style={{
                //   display: "inline",
                // }}
              />
              <ButtonPlayer onClick={() => togglePlay(props.element)}>
                {props.element.media ? <PauseOutlined /> : <PlayArrow />}
              </ButtonPlayer>
              <Bar
                type="range"
                min="0"
                style={{
                  accentColor: COLORS.BTN_GREEN,
                  // color: "rgba(80, 151, 255, 0.38)",
                }}
                max={props.element.duration}
                value={props.element.currentTime}
              /> */}
          </AudioBar>
        </DrawerContainer>
        <CustomTextAreaOtter
          style={{
            color: COLORS.COLOR_DARK,
          }}
        >
          {props.children}
        </CustomTextAreaOtter>
      </React.Fragment>
    );
  };

  return (
    <>
      <React.Fragment>
        <ActionBar {...{ breadcrumbs, onBreadcrumbClick, status }} />
        <Container>
          <div style={{ paddingTop: "10px" }}>
            <BoxContainer>
              <Main>
                {value.length !== 0 ? (
                  <Slate editor={editor} value={value}>
                    <Editable
                      readOnly={true}
                      autoFocus
                      renderElement={renderElement}
                      renderLeaf={renderLeaf}
                    />
                  </Slate>
                ) : null}
              </Main>
            </BoxContainer>
          </div>
        </Container>
      </React.Fragment>
    </>
  );
};

export default CrossExaminationTranscript;

export const Container = styled.div`
  padding: 4px 43px;
  display: flex;
  flex-direction: column;
`;

const Main = styled.section`
  background-color: ${COLORS.PRIMARY_WHITE};
  height: 590px;
  position: relative;
  overflow-y: scroll;
  scrollbar-width: thin;
  border-radius: 10px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  box-shadow: 0 0 5px rgba(80, 151, 255, 0.68);
`;

const DrawerContainer = styled.div`
  width: 100%;
  padding: 10px 23px;
  display: flex;
  align-items: center;
`;

const ValueSpeaker = styled.span`
  width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  padding-right: 6px;
  padding-left: 10px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

export const StyledDropdownIcon = styled.img`
  height: 4px;
  width: 7px;
  background-color: ${COLORS.INPUT_BACKGROUND};
  position: absolute;
  right: 13px;
`;

const BoxContainer = styled.div`
  background-color: ${COLORS.BORDER_GREY};
  position: relative;
  border-radius: 10px;
`;

export const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 15px;
  margin-bottom: 20px;
  &:nth-child(5n) {
    margin-bottom: 25px;
  }
`;

export const Value = styled.div`
  height: 10px;
  display: block;
  align-items: center;
  margin-bottom: 20px;
`;

const CustomTextAreaOtter = styled.div`
  width: 80%;
  font-size: 16px;
  margin-left: 125px;
  margin-right: 25px;
  margin-bottom: 8px;
  line-height: 1.5;
  outline: none;
  border: none;
  resize: none;
  font-family: ${theme.fonts.primaryFontSemiBold};
  background-color: ${COLORS.PRIMARY_WHITE};
  @media ${theme.breakpoints.sm_down} {
    margin-left: 30px;
    font-size: 14px;
  }
`;

const AudioBar = styled.span`
  width: 75%;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  word-break: break-all;
  padding-right: 6px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const ValueConfidence = styled.span`
  width: 48px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  margin-right: 10px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  word-break: break-all;
  padding-right: 6px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;
